* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  background: black;
}

body {
  font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  position: absolute;
  font-size: 40px;
  margin: 40px;
  font-weight: bolder;
  color: white;
  z-index: 100;
}

h2 {
  font-size: 30px;
  margin: 0 0 20px;
  font-weight: bolder;
  color: white;
}

.App {
  width: 100%;
  height: 100%;
}

.top-row-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.top-row {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: absolute;
}

.video {
  position: relative;
  background: #333;
  /*padding-bottom: 56.25%;*/
}

.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.wall {
  background: #111;
  flex: 0 1 100%;
  position: relative;
  overflow-y: hidden;
}

.wall::after {
  height: 170px;
  width: 100%;
  top: 0;
  left: 0;
  content: "";
  position: absolute;
  z-index: 90;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.8) 100%
  );
}

.small {
  font-weight: normal;
  color: #aaa;
  font-size: 20px;
}

.button-wrapper {
  width: 100%;
  position: relative;
  z-index: 2;
}

.button-wrapper--input:before {
  content: '';
  z-index: -1;
  top: -30px;
  left: -30px;
  position: absolute;
  width: calc(100% + 60px);
  height: calc(100% + 60px);
  background: rgba(0, 0, 0, 0.8);
}

.input-wrapper {
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  font-size: 16px;
  margin-bottom: 5px;
  display: inline-block;
}

.input--text, .input--textarea {
  font-size: 16px;
  -webkit-appearance: none;
  padding: 10px;
  border: 0;
  border-radius: 5px;
  width: 100%;
}

.input--radio {
  margin-right: 10px;
  font-size: 20px;
}

.signin-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  min-height: 160px;
  max-height: 100%;
}

.--signin-ui::before {
  height: 170px;
  width: 100%;
  bottom: 0;
  left: 0;
  content: "";
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  color: white;
  padding: 10px;
  font-size: 24px;
  font-weight: bold;
  width: 100%;
  background: rgb(37, 51, 255);
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background: #5a5dff;
}

.tile-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.tile {
  flex: 0 0 5%;
  padding-bottom: 5%;
  position: relative;
}

.tile--current:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  box-shadow: 0 0 10px 10px rgba(37, 51, 255, 0.7);
  z-index: 10;
  pointer-events: none;
}

.tile__image-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}

.tile__image {
  display: block;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tile__text {
  display: inline;
  padding: 10px;
  color: white;
  background: rgba(20, 20, 20, 0.9);
  font-size: 14px;
  margin: 0;
  position: absolute;
  bottom: calc(100% - 10px);
  left: 50%;
  border-radius: 5px;
  text-align: center;
  transform: translate(-50%, 10px);
  opacity: 0;
  pointer-events: none;
  max-width: 240px;
  transition: opacity 120ms linear, transform 120ms ease-in-out;
  z-index: 15;
}


/* Center Tiles */
.tile:hover .tile__text {
  transform: translate(-50%, 0);
  opacity: 1;
}

.tile__text::before {
  content: "";
  background: #1e1e1e;
  width: 22px;
  height: 22px;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  border-radius: 3px;
  z-index: -1;
}

/* Right tiles */
.tile:nth-of-type(5n) .tile__text {
  right: 10px;
  left: initial;
  transform: translateY(0px);
}

.tile:nth-of-type(5n):hover .tile__text {
  transform: translate(0);
}

.tile:nth-of-type(5n) .tile__text::before {
  left: initial;
  right: 5px;
}

/* Left tiles */
.tile:nth-of-type(5n+1) .tile__text {
  left: 10px;
  right: initial;
  transform: translateY(0px);
}

.tile:nth-of-type(5n+1):hover .tile__text {
  transform: translate(0);
}

.tile:nth-of-type(5n+1) .tile__text::before {
  left: 25px;
  right: initial;
}

.tile__message,
.tile__display-name {
  margin: 0;
  width: 220px;
}

.tile__message + .tile__display-name {
  margin-top: 10px;
}

.tile__display-name {
  font-weight: bold;
}

@media (max-width: 800px) {
  .App {
    margin: 0 auto;
    padding: 0;
  }

  h1 {
    font-size: 24px;
    padding: 20px;
    margin: 0;
  }

  .video {
    flex: 0 0 auto;
    padding-bottom: 56.25%;
  }

  .wall {
    flex-shrink: 0;
    flex-grow: 1;
    flex-basis: auto;
  }


  .container {
    height: 100vh;
  }

  .top-row-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0;
  }

  .top-row {
    width: 100%;
    height: calc(100vh - 98px);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    position: absolute;
  }
}
